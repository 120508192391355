import { createTheme } from '@mui/material/styles';
import { colors } from './lib/styles';

const theme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: 'Maven Pro, Roboto, Circular Std, sans-serif',
    h1: {
      fontFamily: 'Libre Baskerville, Times New Roman, Times, serif',
      margin: '15px',
    },
    h2: {
      fontFamily: 'Libre Baskerville, Times New Roman, Times, serif',
      margin: '15px',
    },
    h3: {
      fontFamily: 'Libre Baskerville, Times New Roman, Times, serif',
      margin: '15px',
    },
    h4: {
      fontFamily: 'Libre Baskerville, Times New Roman, Times, serif',
      margin: '15px',
    },
    h5: {
      fontFamily: 'Libre Baskerville, Times New Roman, Times, serif',
      margin: '15px',
    },
    h6: {
      fontFamily: 'Libre Baskerville, Times New Roman, Times, serif',
      margin: '15px',
    },
    body1: {
      fontFamily: 'Maven Pro, Roboto, Circular Std, sans-serif',
      fontSize: 12,
    },
  },
  palette: {
    primary: {
      main: '#1d428a',
    },
    secondary: {
      main: '#a7400e',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          fontFamily: 'Libre Baskerville, Times New Roman, Times, serif',
          fontSize: '12px',
          '&.Mui-disabled': {
            background: colors.blackOpacity,
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          fontFamily: 'Maven Pro, Roboto, Circular Std, sans-serif',
          '& .MuiDataGrid-mainGridContainer': {
          },
          // Rows per page
          '& .MuiTablePagination-selectLabel': {
            fontFamily: 'Maven Pro, Roboto, Circular Std, sans-serif',
          },
          // Dropdown selection
          '& .MuiTablePagination-select': {
            fontFamily: 'Maven Pro, Roboto, Circular Std, sans-serif',
          },
          // Currently viewed of Total entries
          '& .MuiTablePagination-displayedRows': {
            fontFamily: 'Maven Pro, Roboto, Circular Std, sans-serif',
          },
          // Menu item
          '& .MuiMenuItem-root': {
            fontFamily: 'Maven Pro, Roboto, Circular Std, sans-serif',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '14px',
          padding: '12px 10px !important',
        },
      },
    },
  },
});

export default theme;
