import React, { useState, useEffect } from 'react';
import useSWR from 'swr';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';

import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { FormGroup, FormControlLabel } from '@mui/material';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import CheckBox from '@mui/material/Checkbox';

import BackIcon from '@mui/icons-material/ArrowBackIos';

import UserSearch from '../components/user-search';
import ClientSearch from '../components/client-search';
import states from '../lib/states';
import useUser from '../lib/useUser';

import { apiFetch } from '../lib/fetch';
import Layout from '../components/layout';
import {
  Button,
  TextField,
  Select,
  GridForm,
  MaskInput,
} from '../lib/common';
import { cleanNumber } from '../lib/util';

const useStyles = makeStyles({
  error: {
    color: 'red',
    margin: '20px',
    fontSize: '1.5em',
  },
  container: {
    padding: '5px',
  },
  form: {
    width: '80%',
    margin: 'auto',
  },
  saveBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '20px',
  },
  splitBad: {
    color: 'red',
  },
  splitsContainer: {
    fontSize: '1.5em',
  },
  writingAgent: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  typeBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    margin: '20px',
    flexDirection: 'column',
  },
  radioLabel: {
    fontSize: 'large',
  },
});

// eslint-disable-next-line max-len
const NOT_FROM_L_I = 'To the best of my knowledge, this money did not come from the cash value of a life insurance policy.';
const createChangeHandler = (setter) => {
  return (e) => {
    const { value } = e.target || e;
    setter(value);
  };
};

const BSFs = () => {
  const user = useUser();
  const history = useHistory();
  const params = useParams();
  const classes = useStyles();
  const [err, setErr] = useState(null);
  const [submitted_at, setSubmitted_at] = useState(null);
  const [client, setClient] = useState(null);
  const [carrier_id, setCarrier_id] = useState();
  const [state, setState] = useState();

  const [product_type_id, setProduct_type_id] = useState(null);
  const [policy_number, setPolicy_number] = useState();
  const [source_id, setSource_id] = useState('');
  const [funds_type_id, setFunds_type_id] = useState('');
  const [first_distribution, setFirst_distribution] = useState();
  const [life_target, setLife_target] = useState();
  const [death_benefit, setDeath_benefit] = useState();
  const [life_annual, setLife_annual] = useState();
  const [joint, setJoint] = useState(false);
  const [personal, setPersonal] = useState(false);
  const [writing_agent_split, setWriting_agent_split] = useState(100);
  const [agent2, setAgent2] = useState();
  const [agent3, setAgent3] = useState();
  const [agent4, setAgent4] = useState();
  const [agent2Split, setAgent2Split] = useState('');
  const [agent3Split, setAgent3Split] = useState('');
  const [agent4Split, setAgent4Split] = useState('');
  const [saving, setSaving] = useState(false);
  const [type, setType] = useState(null);
  const [new_hh, setNew_hh] = useState(null);
  const [contract_basis, setContract_basis] = useState();
  const [additional_c_basis, setAdditional_c_basis] = useState();
  const [term_months, setTerm_months] = useState();
  const [typeFinished, setTypeFinished] = useState(false);
  const [initial_funding_amount, setInitial_funding_amount] = useState();
  const [errors, setErrors] = useState({});
  const [ack_not_from_l_i_policy, setAck_not_from_l_i_policy] = useState(null);
  const [hedgehogFile, setHedgehogFile] = useState(null);
  const [note_interest_rate, setNote_interest_rate] = useState();

  let policyNumLabel = 'Policy Number';
  let submittedDateLabel = 'Policy Effective Date';
  if (type === 'ANNUITY') {
    policyNumLabel = 'Contract Number';
    submittedDateLabel = 'Policy Effective Date';
  } else if (type === 'HEDGEHOG') {
    policyNumLabel = 'Contract ID';
    submittedDateLabel = 'Note Effective Date';
  } else if (type === 'LANDING_ROCK') {
    policyNumLabel = 'Account Number';
    submittedDateLabel = 'Submitted On';
  } else {
    policyNumLabel = 'Policy Number';
    submittedDateLabel = 'Policy Effective Date';
  }

  function addFile() {
    // console.log('addFile', parent);
    const inputDialog = document.createElement('input');
    inputDialog.id = 'fileUpload';
    inputDialog.type = 'file';
    inputDialog.accept = 'application/pdf';
    inputDialog.click();
    inputDialog.onchange = async (data) => {
      const [selectedFile] = data.target.files;
      if (selectedFile) {
        setHedgehogFile(selectedFile);
      }
    };
  }

  let totalSplits = Number(writing_agent_split);
  if (agent2) {
    totalSplits += Number(agent2Split);
  }
  if (agent3) {
    totalSplits += Number(agent3Split);
  }
  if (agent4) {
    totalSplits += Number(agent4Split);
  }

  useEffect(async () => {
    if (params.clientId && params.householdId) {
      const { clients } = await apiFetch(`/users/${user.id}/household/${params.householdId}/clients`);
      clients.forEach((c) => {
        if (c.id === params.clientId) {
          setClient(c);
        }
      });
    }
  }, []);

  const validSplits = () => {
    if (!joint) {
      return true;
    }
    if (!writing_agent_split) {
      return false;
    }
    if (!agent2 && !agent3 && !agent4) {
      return false;
    }
    if (agent2 && !Number(agent2Split)) {
      return false;
    }
    if (agent3 && !Number(agent3Split)) {
      return false;
    }
    if (agent4 && !Number(agent4Split)) {
      return false;
    }
    return totalSplits === 100;
  };

  const handleSubmit = async () => {
    setErr('');
    setSaving(true);

    // let formData = null;
    const data = {
      writing_agent: user?.id,
      submitted_at,
      carrier_id,
      product_type_id,
      policy_number: String(policy_number).trim(),
      first_distribution: cleanNumber(first_distribution) || undefined,
      state,
      source_id,
      funds_type_id: funds_type_id || undefined,
      life_target: cleanNumber(life_target) || undefined,
      death_benefit: cleanNumber(death_benefit) || undefined,
      life_annual: cleanNumber(life_annual) || undefined,
      joint,
      personal,
      writing_agent_split,
      splits: [],
      client_id: client ? client.id : null,
      type,
      new_hh,
      contract_basis: cleanNumber(contract_basis),
      additional_c_basis,
      term_months,
      initial_funding_amount: cleanNumber(initial_funding_amount) || null,
      ack_not_from_l_i_policy,
      note_interest_rate: cleanNumber(note_interest_rate) || null,
    };

    if (!joint) {
      data.writing_agent_split = 100;
    } else {
      if (agent2) {
        data.splits.push({ agent: agent2.id, split: agent2Split });
      }
      if (agent3) {
        data.splits.push({ agent: agent3.id, split: agent3Split });
      }
      if (agent4) {
        data.splits.push({ agent: agent4.id, split: agent4Split });
      }
    }

    try {
      // await apiFetch.post('/bsf', formData || data);
      const newBSF = await apiFetch.post('/bsf', data);
      if (hedgehogFile) {
        const formData = new FormData();
        formData.append('file', hedgehogFile);
        formData.append('name', hedgehogFile.name);
        formData.append('type', hedgehogFile.type);
        const fileUrl = `/users/${user.id}/bsfs/${newBSF.id}/files`;
        await apiFetch.post(fileUrl, formData);
      }

      history.push('/bsfs');
    } catch (e) {
      setErr(e.toString());
      setSaving(false);
    }
  };

  const handleDateChange = (e) => {
    const newValue = moment(e.target.value).toISOString();
    const isError = moment(newValue).isAfter(moment());
    setErrors({ ...errors, submitted_at: isError });
    setSubmitted_at(newValue);
  };

  let disabled = !carrier_id
  || !policy_number
  || !source_id
  || !validSplits()
  || saving
  || Object.values(errors).some((error) => error === true)
  || (type === 'LIFE' && !death_benefit);

  const { data: carriers } = useSWR('/carriers?orderBy=name&sortOrder=asc&active=true');
  const { data: sources } = useSWR('/sources?orderBy=name&sortOrder=asc&active=true');
  const { data: productTypes } = useSWR('/product_types?orderBy=name&sortOrder=asc&active=true');
  const { data: fundsTypes } = useSWR('/client_fund_types?orderBy=order_val&sortOrder=asc&active=true');

  const filteredCarriers = [];
  const filteredProductTypes = [];

  if (type === 'HEDGEHOG') {
    disabled = disabled || (new_hh === null) || !term_months;
    if (new_hh === true) {
      disabled = disabled || !contract_basis;
    } else if (new_hh === false) {
      disabled = disabled || !additional_c_basis || !contract_basis;
    }
    disabled = disabled || !ack_not_from_l_i_policy;
    disabled = disabled || !hedgehogFile;
    disabled = disabled || !note_interest_rate;
    disabled = disabled || !submitted_at;
  } else if (type === 'LANDING_ROCK') {
    disabled = disabled || !initial_funding_amount;
  } else {
    disabled = disabled || !(first_distribution || ((Number(first_distribution) === 0) && (first_distribution !== '')));
    disabled = disabled || !product_type_id;
    disabled = disabled || (!life_target && !life_annual);
  }

  if (carriers) {
    carriers.forEach((c) => {
      if ((c.code !== 'HEDGEHOG') && (c.code !== 'LANDING_ROCK')) {
        filteredCarriers.push(c);
      }
    });
  }

  if (productTypes) {
    if (type === 'ANNUITY') {
      productTypes.forEach((c) => {
        if (c.annuity) {
          filteredProductTypes.push(c);
        }
      });
    } else if (type === 'LIFE') {
      productTypes.forEach((c) => {
        if (!c.annuity) {
          filteredProductTypes.push(c);
        }
      });
    }
  }

  const handleBSFTypeChange = (e) => {
    const { value } = e.target;
    setType(value);

    if (value === 'HEDGEHOG') {
      setCarrier_id(carriers.find((c) => c.code === 'HEDGEHOG').id);
      setProduct_type_id(null);
      setState(null);
      setLife_target(null);
      setLife_annual(null);
    } else if (value === 'LANDING_ROCK') {
      setCarrier_id(carriers.find((c) => c.code === 'LANDING_ROCK').id);
      setProduct_type_id(null);
      setState(null);
      setLife_target(null);
      setLife_annual(null);
    } else {
      setNew_hh(null);
      setContract_basis(null);
      setAdditional_c_basis(null);
      setTerm_months(null);
    }
  };

  if (!typeFinished) {
    return (
      <Layout>
        <Box className={classes.container}>
          <div className={classes.typeBox}>
            <div>
              <Typography variant="h5">Select the BSF type:</Typography>
            </div>
            <div>
              <RadioGroup
                aria-label="bsf-type"
                name="personal"
                value={type}
                onChange={handleBSFTypeChange}
              >
                <FormControlLabel
                  value="LIFE"
                  control={<Radio />}
                  label={<span className={classes.radioLabel}>Life</span>}
                />
                <FormControlLabel
                  value="ANNUITY"
                  control={<Radio />}
                  label={<span className={classes.radioLabel}>Annuity</span>}
                />
                <FormControlLabel
                  value="HEDGEHOG"
                  control={<Radio />}
                  label={<span className={classes.radioLabel}>Hedgehog</span>}
                />
                <FormControlLabel
                  value="LANDING_ROCK"
                  control={<Radio />}
                  label={<span className={classes.radioLabel}>Landing Rock</span>}
                />
              </RadioGroup>
            </div>
            <div
              style={{
                marginTop: '20px',
              }}
            >
              <Button
                onClick={() => {
                  setTypeFinished(true);
                }}
                disabled={!type}
              >
                Select
              </Button>
            </div>
          </div>
        </Box>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className={classes.error}>{err?.toString()}</div>
      <Box className={classes.container}>
        {carriers && sources && productTypes ? (
          <form className={classes.form}>
            <div className={classes.writingAgent}>
              <Button
                sx={{ marginLeft: '15px' }}
                variant="outlined"
                onClick={() => {
                  setTypeFinished(false);
                }}
                startIcon={<BackIcon />}
              >
                Back
              </Button>
              <Typography variant="h6">BSF TYPE: {type}</Typography>
              <Typography variant="h5">Writing Agent: {user?.email}</Typography>
            </div>
            <GridForm>
              <Grid container spacing={2}>
                {type === 'HEDGEHOG' ? (
                  <TextField
                    label={submittedDateLabel}
                    value={submitted_at}
                    maxDate={new Date()}
                    name="submitted_at"
                    onChange={handleDateChange}
                    type="date"
                  />
                ) : ''}
                <Grid item xs={6}>
                  <ClientSearch
                    defaultValue=""
                    value={client}
                    onChange={setClient}
                  />
                </Grid>
              </Grid>
              {(type !== 'HEDGEHOG') && (type !== 'LANDING_ROCK') ? (
                <Grid container spacing={2}>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Carrier"
                    value={carrier_id}
                    name="carrier_id"
                    onChange={createChangeHandler(setCarrier_id)}
                  >
                    {filteredCarriers
                      .filter((carrier) => carrier.active === true)
                      .map((carrier) => <MenuItem value={carrier.id} key={carrier.id}>{carrier.name}</MenuItem>)}
                  </Select>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Product"
                    value={product_type_id}
                    name="product_type_id"
                    onChange={createChangeHandler(setProduct_type_id)}
                  >
                    {filteredProductTypes
                      .filter((pt) => pt.active === true)
                      .map((pt) => <MenuItem value={pt.id} key={pt.id}>{pt.name}</MenuItem>)}
                  </Select>
                  <Select
                    labelId="demo-simple-select-label"
                    label="Client's State"
                    value={state}
                    name="state"
                    onChange={createChangeHandler(setState)}
                  >
                    {states.map((state) => <MenuItem value={state[0]} key={state[0]}>{state[1]}</MenuItem>)}
                  </Select>
                </Grid>
              ) : ''}
              <Grid container spacing={2}>
                <TextField
                  label={policyNumLabel}
                  defaultValue=""
                  value={policy_number}
                  name="policy_number"
                  onChange={createChangeHandler(setPolicy_number)}
                />
                <Select
                  labelId="demo-simple-select-label"
                  label={type === 'HEDGEHOG' ? 'Client Source' : 'Source'}
                  value={source_id}
                  name="source_id"
                  onChange={createChangeHandler(setSource_id)}
                >
                  {sources
                    .filter((source) => source.active === true)
                    .map((source) => <MenuItem value={source.id} key={source.id}>{source.name}</MenuItem>)}
                </Select>
                {type === 'HEDGEHOG' ? (
                  <Select
                    labelId="demo-simple-select-label"
                    label="Money Source"
                    value={funds_type_id}
                    name="funds_type_id"
                    onChange={createChangeHandler(setFunds_type_id)}
                  >
                    {fundsTypes
                      .map((ft) => <MenuItem value={ft.id} key={ft.id}>{ft.name}</MenuItem>)}
                  </Select>
                ) : ''}
                {type === 'LANDING_ROCK' ? (
                  <MaskInput
                    mask_type="currency"
                    label="Initial Funding Amount"
                    value={initial_funding_amount}
                    name="initial_funding_amount"
                    onChange={createChangeHandler(setInitial_funding_amount)}
                    required
                    expand
                  />
                ) : ''}
                {(type !== 'HEDGEHOG') && (type !== 'LANDING_ROCK') ? (
                  <MaskInput
                    mask_type="currency"
                    label="First Year Tax Free Distribution"
                    value={first_distribution}
                    name="first_distribution"
                    onChange={createChangeHandler(setFirst_distribution)}
                    required
                    expand
                  />
                ) : ''}
              </Grid>
              {(type !== 'HEDGEHOG') && (type !== 'LANDING_ROCK') ? (
                <Grid container spacing={2}>
                  {type === 'LIFE' ? (
                    <>
                      <MaskInput
                        mask_type="currency"
                        disabled={life_annual}
                        label="Projected Target Life Premium"
                        value={life_target}
                        name="life_target"
                        onChange={createChangeHandler(setLife_target)}
                        expand
                      />
                      <MaskInput
                        mask_type="currency"
                        disabled={life_annual}
                        label="Death Benefit"
                        value={death_benefit}
                        name="death_benefit"
                        onChange={createChangeHandler(setDeath_benefit)}
                        expand
                        required={type === 'LIFE'}
                      />
                    </>
                  ) : (
                    <MaskInput
                      mask_type="currency"
                      disabled={life_target}
                      label="Projected Annuity Amount"
                      value={life_annual}
                      name="life_annual"
                      onChange={createChangeHandler(setLife_annual)}
                      expand
                    />
                  )}
                </Grid>
              ) : ''}
            </GridForm>
            {type === 'HEDGEHOG' ? (
              <div>
                <Box
                  sx={{
                    gridArea: 'row1',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="subtitle1" my={3}>Is this new business or a renewal?*</Typography>
                  <RadioGroup
                    aria-label="new_hh"
                    name="new_hh"
                    value={new_hh}
                    onChange={(evt) => {
                      setNew_hh(evt.target.value === 'true');
                    }}
                    row
                  >
                    <FormControlLabel value={true} control={<Radio />} label="New Business" />
                    <FormControlLabel value={false} control={<Radio />} label="Renewal" />
                  </RadioGroup>
                </Box>
                {new_hh !== null ? (
                  <GridForm>
                    <Grid container spacing={2}>
                      <MaskInput
                        mask_type="currency"
                        label={new_hh ? 'New Contract Basis' : 'Previous Contract Basis'}
                        value={contract_basis}
                        name="contract_basis"
                        onChange={createChangeHandler(setContract_basis)}
                      />
                      {new_hh === false ? (
                        <MaskInput
                          mask_type="currency"
                          label="New Contract Basis"
                          value={additional_c_basis}
                          name="additional_c_basis"
                          onChange={createChangeHandler(setAdditional_c_basis)}
                        />
                      ) : ''}
                    </Grid>
                    <Grid container spacing={2}>
                      <Select
                        labelId="term-months-label"
                        label="Contract Term"
                        value={term_months}
                        name="term_months"
                        onChange={createChangeHandler(setTerm_months)}
                      >
                        <MenuItem value={12}>12 Months</MenuItem>
                        <MenuItem value={15}>15 Months</MenuItem>
                        <MenuItem value={18}>18 Months</MenuItem>
                        <MenuItem value={21}>21 Months</MenuItem>
                        <MenuItem value={24}>24 Months</MenuItem>
                        <MenuItem value={30}>30 Months</MenuItem>
                      </Select>
                      <TextField
                        label="Interest Rate"
                        value={note_interest_rate}
                        name="note_interest_rate"
                        type="number"
                        onChange={createChangeHandler(setNote_interest_rate)}
                      />
                    </Grid>
                    <Grid container spacing={2}>
                      <FormGroup
                        style={{
                          marginTop: '20px',
                          marginLeft: '20px',
                        }}
                      >
                        <FormControlLabel
                          control={(
                            <CheckBox
                              checked={!!ack_not_from_l_i_policy}
                              onChange={(event) => {
                                setAck_not_from_l_i_policy(event.target.checked);
                              }}
                              required
                            />
                          )}
                          label={<span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>{NOT_FROM_L_I}</span>}
                        />
                      </FormGroup>
                      {!hedgehogFile ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            margin: '20px',
                            width: '100%',
                          }}
                        >
                          <div
                            style={{
                              fontSize: '1.2em',
                              marginBottom: '10px',
                            }}
                          >
                            Client Contract Disclosure is provided directly from Hedgehog
                          </div>
                          <div>
                            <Button
                              onClick={() => {
                                addFile();
                              }}
                            >
                              Upload Client Contract Disclosure
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div>
                          {hedgehogFile.name}
                        </div>
                      )}
                    </Grid>
                  </GridForm>
                ) : ''}
              </div>
            ) : ''}
            <hr />
            {(type !== 'HEDGEHOG') && (type !== 'LANDING_ROCK') ? (
              <Box
                fullWidth
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(5, 1fr)',
                  gap: 1,
                  gridTemplateRows: 'auto',
                  gridTemplateAreas: `
                  "header header header header header"
                  ". row1 row1 row1 row1"
                  ". row2 row2 row2 row2"
                  ". row3 row3 row3 row3"
                `,
                }}
              >
                <Box
                  sx={{
                    gridArea: 'row1',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="subtitle1">Is this a personal or spousal policy?*</Typography>
                  <RadioGroup
                    aria-label="personal-policy"
                    name="personal"
                    value={personal}
                    onChange={(evt) => {
                      setPersonal(evt.target.value === 'true');
                    }}
                    row
                  >
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                  </RadioGroup>
                </Box>
              </Box>
            ) : ''}
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(5, 1fr)',
                gap: 1,
                gridTemplateRows: 'auto',
                gridTemplateAreas: `
                "header header header header header"
                ". row1 row1 row1 row1"
                ". row2 row2 row2 row2"
                ". row3 row3 row3 row3"
                ". row4 row4 row4 row4"
                ". row5 row5 row5 row5"
                ". row6 row6 row6 row6"
              `,
              }}
            >
              <Box
                sx={{
                  gridArea: 'row1',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="subtitle1">Is this a joint work case?*</Typography>
                <RadioGroup
                  aria-label="commission-split"
                  name="joint"
                  value={joint}
                  onChange={(evt) => {
                    const isJoint = evt.target.value === 'true';
                    setWriting_agent_split(isJoint ? 0 : 100);
                    setJoint(isJoint);
                  }}
                  row
                >
                  <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  <FormControlLabel value={false} control={<Radio />} label="No" />
                </RadioGroup>
              </Box>
              {!joint ? '' : (
                <>
                  <Box sx={{
                    gridArea: 'row2',
                    display: 'flex',
                    gap: 2,
                    marginBottom: 4,
                  }}
                  >
                    <div className={classes.splitsContainer}>
                      <Typography variant="h6">Total Splits = </Typography>
                      <Typography variant="h6" className={validSplits() ? '' : classes.splitBad}>
                        {totalSplits}%
                      </Typography>
                    </div>
                  </Box>
                  <Box sx={{
                    gridArea: 'row3',
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 2,
                    marginBottom: 4,
                  }}
                  >
                    <Grid container spacing={2}>
                      <TextField
                        disabled
                        label={user?.email}
                        xs={10}
                      />
                      <TextField
                        label="Split percentage"
                        type="number"
                        value={writing_agent_split}
                        onChange={createChangeHandler(setWriting_agent_split)}
                        xs={2}
                      />
                    </Grid>
                  </Box>
                  <Box sx={{
                    gridArea: 'row4',
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 2,
                    marginBottom: 4,
                  }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={10}>
                        <UserSearch
                          defaultValue=""
                          ignore={[user?.id]}
                          value={agent2}
                          onChange={setAgent2}
                        />
                      </Grid>
                      <TextField
                        label="Split percentage"
                        type="number"
                        value={agent2Split}
                        onChange={createChangeHandler(setAgent2Split)}
                        xs={2}
                      />
                    </Grid>
                  </Box>
                  <Box sx={{
                    gridArea: 'row5',
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 2,
                    marginBottom: 4,
                  }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={10}>
                        <UserSearch
                          defaultValue=""
                          ignore={[user?.id]}
                          value={agent3}
                          onChange={setAgent3}
                        />
                      </Grid>
                      <TextField
                        label="Split percentage"
                        type="number"
                        value={agent3Split}
                        onChange={createChangeHandler(setAgent3Split)}
                        xs={2}
                      />
                    </Grid>
                  </Box>
                  <Box sx={{
                    gridArea: 'row6',
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 2,
                    marginBottom: 4,
                  }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={10}>
                        <UserSearch
                          defaultValue=""
                          ignore={[user?.id]}
                          value={agent4}
                          onChange={setAgent4}
                        />
                      </Grid>
                      <TextField
                        label="Split percentage"
                        type="number"
                        value={agent4Split}
                        onChange={createChangeHandler(setAgent4Split)}
                        xs={2}
                      />
                    </Grid>
                  </Box>
                </>
              )}
            </Box>
            <div className={classes.saveBtnContainer}>
              <Button
                onClick={() => {
                  history.push('/bsfs');
                }}
                sx={{ width: '100px' }}
                style={{ marginRight: '50px' }}
              >
                Cancel
              </Button>
              <Button
                gold
                disabled={disabled}
                sx={{ width: '100px' }}
                onClick={handleSubmit}
              >
                Save
              </Button>
            </div>
          </form>
        ) : <CircularProgress/>}
      </Box>

    </Layout>
  );
};

export default BSFs;
