import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { CircularProgress } from '@mui/material';

import { colors } from '../lib/styles';
import { pubValidate } from '../state/user';
import Layout from '../components/layout';

const baseStyles = {
  container: {
    backgroundAttachment: 'scroll',
    backgroundRepeat: 'repeat',
    height: '100%',
    minHeight: '900px',
    padding: '10px',
    color: 'white',
  },
  errorText: {
    color: colors.secondaryAccent,
    height: '18px',
    paddingTop: '18px',
  },
};

const Validate = (props) => {
  const { pubValidate } = props;
  const { token } = props.match.params;
  useMemo(() => {
    pubValidate(token);
  }, [token, pubValidate]);
  const { user } = props;

  if (user.pubValidate) {
    setTimeout(() => {
      document.location.href = '/agent-dashboard';
    }, 2000);
    return (
      <Layout>
        <div style={baseStyles.container} >
          <div>
            Your email has been validated, you will be redirected to complete your onboarding steps.
          </div>
        </div>
      </Layout>
    );
  }

  if (user.validateError) {
    return (
      <Layout>
        <div style={baseStyles.container} >
          <div style={baseStyles.errorText}>
            There was an error validating.
            {' '}
            {user.validateError.toString()}
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      Validating...
      <CircularProgress size={80} thickness={5} />
    </Layout>
  );
};

function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

export default connect(mapStateToProps, { pubValidate })(Validate);
