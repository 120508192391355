import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';

import { apiFetch } from '../lib/fetch';
import { colors } from '../lib/styles';
import { formatPhoneNumber } from '../lib/util';

import CommissionTable from '../components/commission-table';
import Layout from '../components/layout';
import { Button } from '../lib/common';
import { GoogleButton, MicrosoftButton, startOauth } from '../lib/oAuth';

const styles = {
  joinImgContainer: {
    width: '100%',
    position: 'relative',
  },
  joinImg: {
    width: '100%',
  },
  joinMsg: {
    color: 'black',
    align: 'center',
    position: 'absolute',
    top: '0px',
    left: '15px',
    fontFamily: 'Libre Baskerville,Georgia,"Times New Roman",serif',
  },
  joinSub2: {
    color: 'black',
    fontSize: '1.2em',
    fontFamily: 'Maven Pro,Helvetica,Arial,Lucida,sans-serif',
  },
  subBlock: {
    marginTop: '-20px',
  },
  joinDesc: {
    color: 'black',
    fontSize: '1em',
    fontFamily: 'Maven Pro,Helvetica,Arial,Lucida,sans-serif',
    marginTop: '-1em',
    fontWeight: '500',
  },
  divider: {
    color: '#A7400E',
    width: '100px',
    border: '5px solid #A7400E',
  },
  recruitCopy: {
    background: 'white',
    padding: '15px',
    fontFamily: 'Maven Pro',
  },
  hrdiv: {
    backgroundColor: '#A7400E',
    height: '3px',
    width: '100px',
  },
  recruitImg: {
    width: '100%',
    '&:hover': {
      filter: 'grayscale(100%)',
    },
  },
  sponsor: {
    fontSize: '1.5em',
    margin: '10px',
  },
  joinH1: {
    textTransform: 'uppercase',
    color: '#000000!important',
    lineHeight: '1.1em',
    textAlign: 'left',
    fontFamily: 'Libre Baskerville,Georgia,Times New Roman,serif',
  },
  agentForm: {
    color: 'black',
    borderRadius: '5px',
    margin: '10px',
  },
  agentField: {
    display: 'flex',
    flexDirection: 'column',
  },
  scrollable: {
    overflow: 'auto',
  },
  commissionTable: {
    display: 'flex',
    overflow: 'auto',
  },
  oAuthBtn: {
    fontFamily: 'Roboto, Helvetica, sans-serif',
    textTransform: 'none',
    marginTop: 10,
    width: 300,
    fontSize: 15,
    marginBottom: 30,
    cursor: 'pointer',
    textAlign: 'center',
  },
  form: {
    margin: 'auto',
    width: '100%',
    padding: '35px 50px 50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formDivider: {
    marginTop: 30,
    marginBottom: 30,
    width: '100%',
  },
};

const UserForm = () => {
  const params = useParams();
  const { num } = params;
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [oauthProviders, setOauthProviders] = useState(null);
  const [viewEmailPassword, setViewEmailPassword] = useState(false);
  const [open, setOpen] = useState(false);

  let agent;
  if (num) {
    const { data } = useSWR(`/agents/${num}`);
    agent = data;
  }

  const createChangeHandler = (setter) => {
    return (event) => {
      setter(event.target.value);
    };
  };

  useEffect(async () => {
    const result = await startOauth(`r${num || ''}`);
    setOauthProviders(result);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      firstname,
      lastname,
      email,
      phone,
    };

    const { ok } = await apiFetch(`/agents/${num || 0}`, {
      method: 'POST',
      body: data,
    });

    if (ok) {
      setSubmitted(true);
    }
  };

  return (
    <Layout showFull={false}>
      <div style={{ color: 'white', width: '100%' }}>
        {!submitted ? (
          <>
            <div style={styles.joinImgContainer}>
              <img
                style={styles.joinImg}
                src="/images/join-stronghold-wealth-partners1920x1067.jpg"
                alt="join stronghold"
              />
              <div className="joinHeading">
                <h1 style={styles.joinH1}><em>Join</em> <strong>Stronghold Wealth Partners</strong></h1>
              </div>
            </div>

            {agent ? (
              <div style={styles.agentHeader} className="agentEmailHeader">
                <div style={{ fontWeight: 'bold' }}>Agent:</div>
                <div>{agent.firstname} {agent.lastname}</div>
                {agent.phone ? (<div>{formatPhoneNumber(agent.phone)}</div>) : ''}
                {agent.email ? (<div style={styles.scrollable}>{agent.email}</div>) : ''}
              </div>
            ) : ''}

            <div style={styles.recruitCopy}>
              <div>
                <div>
                  <div>
                    <p className="recruitHeader">WARRIOR PROTECTION</p>
                  </div>
                  <div className="recruitSub">
                    Determined. Strong. Honorable. Respectful.
                  </div>
                  <div style={styles.hrdiv}/>
                </div>
                <div>
                  <div>
                    <p>
                      <span style={styles.joinDesc}>
                        Consistently moving forward, we keep pushing to the next level.
                        {/* eslint-disable-next-line max-len */}
                        Never satisfied, we pursue outstanding returns for our clients, for ourselves, and for StrongHold.
                        We think bigger and do better, always advancing and retreating only to advance further.
                      </span>
                    </p>
                    <p>
                      <span style={styles.joinDesc}>
                        As others battle with outdated compensation models,
                        we are proud to share our bounty with our village.
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <div>
                  <div>
                    <p className="recruitHeader">WHY STRONGHOLD?</p>
                  </div>
                  <div className="recruitSub">
                    Enter the Keep.
                  </div>
                  <div style={styles.hrdiv}/>
                </div>
                <div>
                  <div>
                    <p>
                      <span style={styles.joinDesc}>
                        Top Agents become StrongHold Wealth Partners Because of the Honor Among Us.
                      </span>
                    </p>
                    <p>
                      <span style={styles.joinDesc}>
                        At StrongHold Wealth, we look at finance and investment strategies differently.
                        We fortify your finances so you can feel feel confident in the legacy that you have built.
                        Fortifying your legacy is personal to us.
                        We are a competitive, innovative, forward-thinking firm balanced with integrity
                        and the need to always do what&apos;s right. Exceeding your financial goals is where we advance.
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <div>
                  <div>
                    <p className="recruitHeader">BENEFITS</p>
                  </div>
                  <div className="recruitSub">
                    Working with StrongHold
                  </div>
                  <div style={styles.hrdiv}/>
                </div>
                <div>
                  <div>
                    <p>
                      <span style={styles.joinDesc}>
                        When you take your seat at the table as a StrongHold Wealth Partner, you can expect:
                      </span>
                    </p>
                    <div style={{ display: 'flex', color: 'black' }}>
                      <table cellPadding="5">
                        <tbody>
                          <tr>
                            <td width="33%">
                              <img
                                style={styles.recruitImg}
                                src="/images/stronghold-wealth-partners-honor.jpg"
                                alt="honor"
                              />
                            </td>
                            <td width="33%">
                              <img
                                style={styles.recruitImg}
                                src="/images/stronghold-wealth-partners-adventure.jpg"
                                alt="adventure"
                              />
                            </td>
                            <td width="33%">
                              <img
                                style={styles.recruitImg}
                                src="/images/stronghold-wealth-partners-exploration.jpg"
                                alt="exploration"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: 'bold' }}>
                              HONOR
                            </td>
                            <td style={{ fontWeight: 'bold' }}>
                              ADVENTURE
                            </td>
                            <td style={{ fontWeight: 'bold' }}>
                              EXPLORATION
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Living and working with integrity, we are intrinsically motivated to perform.
                              Our competitive nature is balanced by keen observation and a need to do what is right.
                            </td>
                            <td>
                              Active, competitive and hard-driving, we live rather than merely exist
                              and that is what we want for our clients, too.
                              The adventure you value is the one we will help you pursue
                            </td>
                            <td>
                              We search the horizon for new ideas to disrupt, transpose,
                              and change not only the approach to investment
                              and the performance we can expect, but also the industry itself.
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ marginTop: '50px', color: 'black' }}>
                <div>
                  <div className="recruitSub" style={{ marginTop: '10px' }}>
                    Our team of professional agents enjoy:
                  </div>
                </div>
                <div>
                  <div style={{ display: 'flex', fontWeight: '400' }}>
                    <ul>
                      <li>High Contracts</li>
                      <li>Payments on Renewals & Excess Premiums</li>
                      <li>Wealth Share</li>
                      <li>Vesting - Wealth Share vesting</li>
                      <li>Access to RIA with Top Ranked Money Management</li>
                      <li>Commission Automation</li>
                      <li>Live Trainings</li>
                    </ul>
                    <ul>
                      <li>Mentorship</li>
                      <li>Custom Landing Pages</li>
                      <li>Dedicated Business Processing Specialists</li>
                      <li>Quote Engines</li>
                      <li>Proven Marketing Systems</li>
                      <li>Exclusive Products & Product Design</li>
                    </ul>
                  </div>
                  <p>
                    <span style={styles.joinDesc}>
                      We give you the freedom and guidance to run your own book of business
                      the way you would run a company like StrongHold.
                      Expect strategy and collaboration, as well as education.
                    </span>
                  </p>
                </div>
              </div>

              <div>
                <div>
                  <div>
                    <p className="recruitHeader">STRONGHOLD</p>
                  </div>
                  <div className="recruitSub">
                    Fortify <strong><i>Your</i></strong> Future.
                  </div>
                  <div style={styles.hrdiv}/>
                </div>
                <div>
                  <div>
                    <p>
                      <span style={styles.joinDesc}>
                        StrongHold agents who come into the fold are given the rare opportunity to share in
                        the success of the partnership as a whole.
                      </span>
                    </p>
                    <p>
                      <span style={styles.joinDesc}>
                        You cannot protect, shield or shelter that which you have not built. Fortify your foundation.
                      </span>
                    </p>
                    <p>
                      <span style={styles.joinDesc}>
                        Strategize your plan of attack to fortify finances…both theirs and yours.
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <iframe
                  // eslint-disable-next-line max-len
                  src="https://player.vimeo.com/video/716761644?h=6f5e3b3d5a&badge=0&autopause=0&player_id=0&app_id=58479"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  style={{
                    width: '100%',
                    minHeight: '350px',
                  }}
                  title="Comp Plan Video"
                />
                <script src="https://player.vimeo.com/api/player.js"/>
              </div>
              <div style={styles.commissionTable}>
                <CommissionTable/>
              </div>

              <div>
                <div>
                  <div>
                    <p className="recruitHeader">JOIN</p>
                  </div>
                  <div className="recruitSub">
                    <h2>The Team</h2>
                  </div>
                  <div style={styles.hrdiv}/>
                </div>
              </div>

              <div style={styles.agentForm}>
                <Box
                  component="form"
                  sx={{
                    marginTop: 2,
                    marginBottom: 20,
                    display: 'grid',
                    width: '100%',
                    '& fieldset': {
                      '& > div': {
                        m: 2,
                      },
                    },
                  }}
                  onSubmit={handleSubmit}
                >
                  <div>
                    {agent ? (
                      <div style={styles.sponsor}>Sponsoring Agent: {agent.firstname} {agent.lastname}</div>
                    ) : ''}
                  </div>
                  <Button
                    onClick={() => setOpen(true)}
                    style={styles.oAuthBtn}
                  >
                    Join Now
                  </Button>
                  <Dialog fullWidth open={open} maxWidth="sm" onClose={() => setOpen(false)}>
                    <DialogTitle>Join StrongHold Today</DialogTitle>
                    <DialogContent sx={{ display: 'flex', alignContent: 'center' }}>
                      <div style={styles.form}>
                        {viewEmailPassword ? (
                          <>
                            <Grid container spacing={3}>
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  label="First Name"
                                  variant="outlined"
                                  value={firstname}
                                  onChange={createChangeHandler(setFirstName)}
                                  sx={{
                                    '& .Mui-focused': {
                                      WebkitTextFillColor: colors.darkAccent,
                                    },
                                    // Input
                                    '& .MuiInputBase-root': {
                                      color: colors.darkAccent,
                                      '& .Mui-disabled': {
                                        WebkitTextFillColor: colors.darkAccent,
                                      },
                                    },
                                    // Label
                                    '& .MuiFormLabel-root': {
                                      color: colors.darkAccent,
                                    },
                                    // Border
                                    '& .MuiOutlinedInput-root': {
                                      '& > fieldset': {
                                        borderColor: colors.darkAccent,
                                      },
                                    },
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  label="Last Name"
                                  variant="outlined"
                                  value={lastname}
                                  onChange={createChangeHandler(setLastName)}
                                  sx={{
                                    flexGrow: 1,
                                    '& .Mui-focused': {
                                      WebkitTextFillColor: colors.darkAccent,
                                    },
                                    // Input
                                    '& .MuiInputBase-root': {
                                      color: colors.darkAccent,
                                      '& .Mui-disabled': {
                                        WebkitTextFillColor: colors.darkAccent,
                                      },
                                    },
                                    // Label
                                    '& .MuiFormLabel-root': {
                                      color: colors.darkAccent,
                                    },
                                    // Border
                                    '& .MuiOutlinedInput-root': {
                                      '& > fieldset': {
                                        borderColor: colors.darkAccent,
                                      },
                                    },
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  label="Email"
                                  variant="outlined"
                                  value={email}
                                  onChange={createChangeHandler(setEmail)}
                                  sx={{
                                    '& .Mui-focused': {
                                      WebkitTextFillColor: colors.darkAccent,
                                    },
                                    // Input
                                    '& .MuiInputBase-root': {
                                      color: colors.darkAccent,
                                      '& .Mui-disabled': {
                                        WebkitTextFillColor: colors.darkAccent,
                                      },
                                    },
                                    // Label
                                    '& .MuiFormLabel-root': {
                                      color: colors.darkAccent,
                                    },
                                    // Border
                                    '& .MuiOutlinedInput-root': {
                                      '& > fieldset': {
                                        borderColor: colors.darkAccent,
                                      },
                                    },
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  label="Phone"
                                  type="number"
                                  variant="outlined"
                                  value={phone}
                                  onChange={createChangeHandler(setPhone)}
                                  sx={{
                                    '& .Mui-focused': {
                                      WebkitTextFillColor: colors.darkAccent,
                                    },
                                    // Input
                                    '& .MuiInputBase-root': {
                                      color: colors.darkAccent,
                                      '& .Mui-disabled': {
                                        WebkitTextFillColor: colors.darkAccent,
                                      },
                                    },
                                    // Label
                                    '& .MuiFormLabel-root': {
                                      color: colors.darkAccent,
                                    },
                                    // Border
                                    '& .MuiOutlinedInput-root': {
                                      '& > fieldset': {
                                        borderColor: colors.darkAccent,
                                      },
                                    },
                                  }}
                                  inputProps={{ type: 'numeric', inputMode: 'tel' }}
                                />
                              </Grid>
                            </Grid>
                            <Button
                              gold
                              sx={{
                                my: 2,
                                width: '100%',
                                justifySelf: 'flex-end',
                              }}
                              color="primary"
                              variant="contained"
                              type="submit"
                              onClick={handleSubmit}
                            >
                              Submit
                            </Button>
                          </>
                        ) : (
                          <>
                            {oauthProviders?.google && (
                              <GoogleButton
                                authUrl={oauthProviders?.google?.url}
                                label="Join with Google"
                              />
                            )}
                            {oauthProviders?.microsoft && (
                              <MicrosoftButton
                                authUrl={oauthProviders?.microsoft?.url}
                                label="Join with Microsoft"
                              />
                            )}
                          </>
                        )}
                        <Divider color="#888" style={styles.formDivider} />
                        {/* eslint-disable-next-line */}
                        <Link onClick={() => setViewEmailPassword(!viewEmailPassword)} style={styles.oAuthBtn}>
                          Join with {viewEmailPassword ? 'an authentication provider' : 'email'}
                        </Link>
                      </div>
                    </DialogContent>
                  </Dialog>
                </Box>
              </div>
            </div>
          </>
        ) : (
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              color: 'black',
              marginTop: '135px',
            }}
          >
            <Typography variant="h5" style={{ color: 'white' }}>
              Thank you for registering.<br/>
              You&apos;ll be receiving an email from admin@strongholdpartners.com.<br/>
              Please check your email to confirm registration.<br/><br/>
              If you don&apos;t recieve this email in the next few minutes, please check your spam folder.
            </Typography>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default UserForm;
