import React, { useEffect, useState } from 'react';
import moment from 'moment';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import { DataGrid } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  MenuItem,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import UserSearch from './user-search';
import ClientSearch from './client-search';
import { Button, Select } from '../lib/common';
import useUser from '../lib/useUser';
import { apiFetch } from '../lib/fetch';
import DeleteButton from './delete-button';

const useStyles = makeStyles({
  content: {
    width: '100%',
    padding: '10px',
  },
  w200: {
    width: '200px',
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 225,
  },
  tabPanel: {
    padding: '10px',
  },
});

const clientMergeCols = [
  { field: 'id', hide: true },
  {
    field: 'num',
    headerName: '#',
    align: 'left',
    width: 150,
  },
  {
    field: 'firstname',
    headerName: 'First Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'lastname',
    headerName: 'Last Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'age',
    headerName: 'Age',
    align: 'left',
    width: 150,
    renderCell: (params) => {
      return params.row.dob ? moment().diff(params.row.dob, 'years', false) : '';
    },
  },
  {
    field: 'dob',
    headerName: 'DOB',
    align: 'left',
    width: 200,
    renderCell: (params) => {
      return params.row.dob ? moment(params.row.dob).format('YYYY-MM-DD') : '';
    },
  },
];

function HouseholdSettings(props) {
  const { household, displays, updateLocalHH } = props;
  const classes = useStyles();
  const user = useUser();
  const [shares, setShares] = useState([]);
  const [requests, setRequests] = useState([]);
  const [accessType, setAccessType] = useState('READ');
  const [accessAgent, setAccessAgent] = useState(null);
  const [primaryClient, setPrimaryClient] = useState(household.primary_client);
  const [tabIndex, setTabIndex] = useState(0);
  const [toMerge, setToMerge] = useState(null);
  const [mergeIncludeNotes, setMergeIncludeNotes] = useState(null);
  const [copySplitNotes, setCopySplitNotes] = useState(null);
  const [clientForSplit, setClientForSplit] = useState(null);
  const [clientForDelete, setClientForDelete] = useState(null);

  const refreshRecords = async () => {
    const requestRecords = await apiFetch(`/users/${user.id}/household/${household.id}/request`);
    const shareRecords = await apiFetch(`/users/${user.id}/household/${household.id}/share`);
    setRequests(requestRecords);
    setShares(shareRecords);
  };

  const requestColumns = [
    { field: 'id', hide: true },
    {
      field: 'share_firstname',
      headerName: 'First',
      align: 'left',
      width: 150,
    },
    {
      field: 'share_lastname',
      headerName: 'Last',
      align: 'left',
      width: 150,
    },
    {
      field: 'share_num',
      headerName: 'Agent #',
      align: 'left',
      width: 150,
    },
    {
      field: 'type',
      headerName: 'Access',
      align: 'left',
      width: 150,
    },
    {
      field: 'status',
      headerName: 'Status',
      align: 'left',
      width: 100,
    },
    {
      field: 'invite',
      headerName: 'Invite?',
      align: 'left',
      width: 150,
      renderCell: (params) => {
        if (params.row.invite) {
          return 'INVITE';
        }
        return 'AGENT REQUEST';
      },
    },
    {
      field: 'action',
      renderHeader: () => (null),
      align: 'left',
      width: 200,
      renderCell: (params) => {
        if (params.row.invite) {
          return (
            <DeleteButton
              handleDelete={async () => {
                try {
                  const path = `/users/${user.id}/household/${household.id}/request/${params.row.id}`;
                  await apiFetch.del(path);
                  await refreshRecords();
                } catch (e) {
                  // eslint-disable-next-line
                  console.log('error accepting request', e);
                }
              }}
            />
          );
        }
        return (
          <Button
            onClick={async () => {
              try {
                const path = `/users/${user.id}/household/${household.id}/request/${params.row.id}`;
                await apiFetch.put(path, {
                  action: 'ACCEPT',
                });
                await refreshRecords();
              } catch (e) {
                // eslint-disable-next-line
                console.log('error accepting request', e);
              }
            }}
          >
            APPROVE
          </Button>
        );
      },
    },
    {
      field: 'action2',
      renderHeader: () => (null),
      align: 'left',
      width: 200,
      renderCell: (params) => {
        if (!params.row.invite) {
          return (
            <Button
              onClick={async () => {
                try {
                  const path = `/users/${user.id}/household/${household.id}/request/${params.row.id}`;
                  await apiFetch.put(path, {
                    action: 'REJECT',
                  });
                  await refreshRecords();
                } catch (e) {
                  // eslint-disable-next-line
                  console.log('error accepting request', e);
                }
              }}
            >
              REJECT
            </Button>
          );
        }
        return '';
      },
    },
  ];

  const clientSplitCols = [
    { field: 'id', hide: true },
    {
      field: 'num',
      headerName: '#',
      align: 'left',
      width: 150,
    },
    {
      field: 'firstname',
      headerName: 'First Name',
      align: 'left',
      width: 150,
    },
    {
      field: 'lastname',
      headerName: 'Last Name',
      align: 'left',
      width: 150,
    },
    {
      field: 'age',
      headerName: 'Age',
      align: 'left',
      width: 150,
      renderCell: (params) => {
        return params.row.dob ? moment().diff(params.row.dob, 'years', false) : '';
      },
    },
    {
      field: 'dob',
      headerName: 'DOB',
      align: 'left',
      width: 200,
      renderCell: (params) => {
        return params.row.dob ? moment(params.row.dob).format('YYYY-MM-DD') : '';
      },
    },
    {
      field: 'split',
      headerName: 'Split',
      align: 'left',
      width: 200,
      renderCell: (params) => {
        const hasEmail = params.row.email && params.row.email.length > 0;
        return (
          <>
            <Button
              disabled={!hasEmail}
              onClick={async () => {
                setClientForSplit(params.row);
              }}
            >
              {hasEmail ? 'Split Client Out' : 'Needs an email'}
            </Button>
            {!hasEmail ? (
              <Tooltip
                // eslint-disable-next-line
                title="A client must have an email address saved on their profile before being split out to their own household."
              >
                <InfoIcon/>
              </Tooltip>
            ) : ''}
          </>
        );
      },
    },
  ];

  const clientDelCols = [
    { field: 'id', hide: true },
    {
      field: 'num',
      headerName: '#',
      align: 'left',
      width: 150,
    },
    {
      field: 'firstname',
      headerName: 'First Name',
      align: 'left',
      width: 150,
    },
    {
      field: 'lastname',
      headerName: 'Last Name',
      align: 'left',
      width: 150,
    },
    {
      field: 'age',
      headerName: 'Age',
      align: 'left',
      width: 150,
      renderCell: (params) => {
        return params.row.dob ? moment().diff(params.row.dob, 'years', false) : '';
      },
    },
    {
      field: 'dob',
      headerName: 'DOB',
      align: 'left',
      width: 200,
      renderCell: (params) => {
        return params.row.dob ? moment(params.row.dob).format('YYYY-MM-DD') : '';
      },
    },
    {
      field: 'del',
      headerName: 'Delete',
      align: 'left',
      width: 200,
      renderCell: (params) => {
        return (
          <>
            <Button
              onClick={async () => {
                setClientForDelete(params.row);
              }}
            >
              Delete
            </Button>
            <Tooltip
              // eslint-disable-next-line
              title="A client with BSFs may not be deleted."
            >
              <InfoIcon/>
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(async () => {
    await refreshRecords();
  }, []);

  async function handleInvite() {
    const path = `/users/${user.id}/household/${household.id}/invite`;
    await apiFetch.post(path, {
      agent_id: accessAgent.id,
      type: accessType,
    });
    await refreshRecords();
  }

  function MergePanel() {
    return (
      <Paper
        className={classes.tabPanel}
        style={{ display: tabIndex === 1 ? '' : 'none' }}
      >
        <h2>Search Household:</h2>
        <div>
          <ClientSearch
            excludeHousehold={household.id}
            onChange={async (c) => {
              if (c) {
                const mergeDetails = await apiFetch(`/users/${user.id}/household/${c.household_id}/clients`);
                setToMerge(mergeDetails);
              } else {
                setToMerge(null);
              }
            }}
          />
        </div>
        {toMerge ? (
          <div>
            <div style={{ fontSize: '1.5em', fontWeight: 'bold', marginTop: '20px' }}>Selected Household to Merge:</div>
            <Paper>
              <div className={toMerge.clients.length ? classes.gridContainer : classes.emptyGridContainer}>
                <DataGrid columns={clientMergeCols} rows={toMerge.clients} />
              </div>
            </Paper>
            <div
              style={{
                alignContent: 'right',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                marginTop: '20px',
              }}
            >
              <div>
                <div style={{ fontSize: '1.5em', fontWeight: 'bold' }}>Include Notes History?</div>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="radio-buttons-group-label"
                    defaultValue={null}
                    name="radio-buttons-group"
                    value={mergeIncludeNotes}
                    onChange={(e) => {
                      setMergeIncludeNotes(e.target.value);
                    }}
                  >
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                    <FormControlLabel value={false} control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <Button
                  onClick={async () => {
                    // console.log('merge', toMerge);
                    const updatedHH = await apiFetch.put(`/users/${user.id}/households/merge`, {
                      from_household_id: toMerge.id,
                      to_household_id: household.id,
                      moveNotes: mergeIncludeNotes,
                    });
                    setToMerge(null);
                    setMergeIncludeNotes(null);
                    updateLocalHH(updatedHH);
                  }}
                  disabled={mergeIncludeNotes === null}
                  style={{ marginTop: '20px' }}
                >
                  Merge this Household In
                </Button>
              </div>
            </div>
          </div>
        ) : ''}
      </Paper>
    );
  }

  function SplitPanel() {
    return (
      <Paper
        className={classes.tabPanel}
        style={{ display: tabIndex === 2 ? '' : 'none' }}
      >
        {household.clients.length === 1 ? (
          <h2>There is only one client in this household</h2>
        ) : (
          <div>
            <h2>Split a Client out to new Household:</h2>
            <Paper>
              <div className={household.clients.length ? classes.gridContainer : classes.emptyGridContainer}>
                <DataGrid columns={clientSplitCols} rows={household.clients || []} />
              </div>
            </Paper>
          </div>
        )}
        <Dialog open={!!clientForSplit} disableScrollLock>
          <DialogTitle>Confirm Splitting out {clientForSplit?.firstname} {clientForSplit?.lastname}</DialogTitle>
          <div style={{ margin: '20px' }}>
            <div>
              <div style={{ fontSize: '1.5em', fontWeight: 'bold' }}>Copy Notes History to new Household?</div>
              <FormControl>
                <RadioGroup
                  aria-labelledby="radio-buttons-group-label"
                  defaultValue={null}
                  name="radio-buttons-group"
                  value={copySplitNotes}
                  onChange={(e) => {
                    setCopySplitNotes(e.target.value);
                  }}
                >
                  <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  <FormControlLabel value={false} control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <Button
                onClick={async () => {
                  setClientForSplit(null);
                  setCopySplitNotes(null);
                }}
                style={{ margin: '20px' }}
              >
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  // console.log('merge', toMerge);
                  const updatedHH = await apiFetch.put(`/users/${user.id}/clients/${clientForSplit.id}/split`, {
                    copyNotes: copySplitNotes,
                  });
                  setClientForSplit(null);
                  setCopySplitNotes(null);
                  updateLocalHH(updatedHH);
                }}
                style={{ margin: '20px' }}
                disabled={copySplitNotes === null}
              >
                Yes, Split Client Out
              </Button>
            </div>
          </div>
        </Dialog>
      </Paper>
    );
  }

  function DelClientPanel() {
    const [clientBsfs, setClientBsfs] = useState(null);
    const [deleting, setDeleting] = useState(false);
    useEffect(async () => {
      if (clientForDelete) {
        const bsfs = await apiFetch(`/clients/${clientForDelete.id}/bsf`);
        setClientBsfs(bsfs);
      }
    }, [clientForDelete]);
    return (
      <Paper
        className={classes.tabPanel}
        style={{ display: tabIndex === 3 ? '' : 'none' }}
      >
        <div>
          <h2>Delete a Client:</h2>
          <Paper>
            <div className={household.clients.length ? classes.gridContainer : classes.emptyGridContainer}>
              <DataGrid columns={clientDelCols} rows={household.clients || []} />
            </div>
          </Paper>
        </div>
        <Dialog open={!!clientForDelete} disableScrollLock>
          <DialogTitle>Confirm Deleting {clientForDelete?.firstname} {clientForDelete?.lastname}</DialogTitle>
          <div style={{ margin: '20px' }}>
            <div>
              {clientBsfs && clientBsfs.length ? (
                <div style={{ fontSize: '1.5em', fontWeight: 'bold' }}>
                  This client has {clientBsfs.length} BSF{clientForDelete.length > 1 ? 's' : ''} and cannot be deleted.
                </div>
              ) : ''}
            </div>
            <div>
              <Button
                onClick={async () => {
                  setClientForDelete(null);
                }}
                style={{ margin: '20px' }}
              >
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  setDeleting(true);
                  const updatedDel = await apiFetch.put(`/users/${user.id}/clients/${clientForDelete.id}/soft-del`, {});
                  if (updatedDel.emptyHousehold) {
                    document.location.href = '/client-management';
                    return;
                  }
                  document.location.href = `/household/${updatedDel.household.id}`;
                }}
                style={{ margin: '20px' }}
                disabled={!clientBsfs || clientBsfs.length || deleting}
              >
                Yes, Delete this Client
              </Button>
            </div>
          </div>
        </Dialog>
      </Paper>
    );
  }

  function TransferButton(props) {
    const { params } = props;
    const [transferOpen, setTransferOpen] = useState(false);
    return (
      <>
        <Button
          onClick={async () => {
            setTransferOpen(true);
          }}
        >
          Transfer Ownership
        </Button>
        <Dialog
          open={transferOpen}
        >
          <div style={{ margin: '5px' }}>
            <Typography
              variant="h5"
            >
              Are you sure you want to transfer ownership of this household
              to {params.row.share_firstname} {params.row.share_lastname}?
            </Typography>
            <div>
              <Button
                style={{ margin: '10px' }}
                onClick={async () => {
                  setTransferOpen(false);
                  try {
                    const path = `/users/${user.id}/household/${household.id}/ownership`;
                    await apiFetch.put(path, {
                      new_owner_id: params.row.agent_id,
                    });
                    await refreshRecords();
                  } catch (e) {
                    // eslint-disable-next-line
                    console.log('error transferring request', e);
                  }
                }}
              >
                Transfer
              </Button>
              <Button
                style={{ margin: '10px' }}
                onClick={() => {
                  setTransferOpen(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Dialog>
      </>
    );
  }

  function SharingPanel() {
    const shareColumns = [
      { field: 'id', hide: true },
      {
        field: 'share_firstname',
        headerName: 'First',
        align: 'left',
        width: 150,
      },
      {
        field: 'share_lastname',
        headerName: 'Last',
        align: 'left',
        width: 150,
      },
      {
        field: 'share_num',
        headerName: 'Agent #',
        align: 'left',
        width: 150,
      },
      {
        field: 'type',
        headerName: 'Access',
        align: 'left',
        width: 150,
        renderCell: (params) => {
          return (
            <Select
              value={params.row.type}
              onChange={async (e) => {
                try {
                  const path = `/users/${user.id}/household/${household.id}/share/${params.row.id}`;
                  await apiFetch.put(path, {
                    type: e.target.value,
                  });
                  await refreshRecords();
                  document.location.href = '/client-management';
                } catch (e) {
                  // eslint-disable-next-line
                  console.log('error accepting request', e);
                }
              }}
            >
              <MenuItem value="READ">
                <span style={{ marginRight: '20px' }}>read-only </span>
              </MenuItem>
              <MenuItem value="EDIT">
                <span style={{ marginRight: '20px' }}>edit </span>
              </MenuItem>
            </Select>
          );
        },
      },
      {
        field: 'action',
        renderHeader: () => (null),
        align: 'left',
        width: 75,
        renderCell: (params) => {
          return (
            <DeleteButton
              handleDelete={async () => {
                try {
                  const path = `/users/${user.id}/household/${household.id}/share/${params.row.id}`;
                  await apiFetch.del(path);
                  await refreshRecords();
                } catch (e) {
                  // eslint-disable-next-line
                  console.log('error accepting request', e);
                }
              }}
            />
          );
        },
      },
      {
        field: 'transferAction',
        renderHeader: () => (null),
        align: 'left',
        width: 200,
        renderCell: (params) => {
          if (params.row.type !== 'EDIT') {
            return '';
          }
          if (household.agent_id !== user.id) {
            return '';
          }
          return (
            <TransferButton params={params} />
          );
        },
      },
    ];
    return (
      <Paper
        className={classes.tabPanel}
        style={{ display: tabIndex === 0 ? '' : 'none' }}
      >
        <div>
          <h2>Invite:</h2>
          <Box sx={{
            gridArea: 'row4',
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2,
            marginBottom: 4,
          }}
          >
            <UserSearch
              ignore={[user.id]}
              value={accessAgent}
              onChange={(u) => {
                setAccessAgent(u);
              }}
            />
            <Select
              label="Access"
              value={accessType}
              name="marital_status"
              style={{ width: 200, height: 50 }}
              onChange={(e) => {
                setAccessType(e.target.value);
              }}
            >
              <MenuItem value="READ">Read-Only</MenuItem>
              <MenuItem value="EDIT">Edit</MenuItem>
            </Select>
            <Button
              disabled={!accessAgent}
              onClick={() => {
                handleInvite();
              }}
            >
              Invite
            </Button>
          </Box>
        </div>

        <h2>Shared with Agents:</h2>
        <Paper>
          <div className={classes.gridContainer}>
            <DataGrid columns={shareColumns} rows={shares || []} />
          </div>
        </Paper>

        <h2>Share Requests:</h2>
        <Paper>
          <div className={classes.gridContainer}>
            <DataGrid columns={requestColumns} rows={requests || []} />
          </div>
        </Paper>
      </Paper>
    );
  }

  function HouseholdHeader() {
    return (
      <div>
        <Select
          value={primaryClient || 'null'}
          name="primary_client"
          label="Primary Client"
          onChange={async (e) => {
            const { value } = e.target;
            setPrimaryClient(value);
            const path = `/users/${user.id}/household/${household.id}/primary`;
            household.primary_client = value;
            await apiFetch.put(path, {
              primary_client: value === 'null' ? null : value,
            });
          }}
        >
          <MenuItem value="null">-</MenuItem>
          {household.clients.map((i) => {
            const cDisplay = displays[i.id] || {};
            let name = `${i.firstname} ${i.lastname}`;
            if (cDisplay.age_years) {
              name += ` ${cDisplay.age_years}y ${cDisplay.age_months}m`;
            } else {
              name += ' - no DOB';
            }
            return (
              <MenuItem value={i.id} key={i.id}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
      </div>
    );
  }

  return (
    <div className={classes.content}>
      <h1>
        Household: {household.name}
        <Button
          style={{ margin: '10px' }}
          onClick={props.handleClose}
          startIcon={<PeopleAltIcon />}
        >
          Back To Client Profile(s)
        </Button>
      </h1>

      <HouseholdHeader />
      <Tabs
        value={tabIndex}
        onChange={(e, v) => {
          setTabIndex(v);
        }}
        centered
      >
        <Tab label="Sharing"/>
        <Tab label="Merge Household In"/>
        <Tab label="Split Client Out"/>
        <Tab label="Delete Client"/>
      </Tabs>
      <SharingPanel />
      <MergePanel />
      <SplitPanel />
      <DelClientPanel />
    </div>
  );
}

export default HouseholdSettings;
